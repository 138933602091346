<template>
  <div>
    <b-modal id="modal-prevent-closing" ref="modal" :title="$t('addPart.edit_part_dailog_heading')" centered no-close-on-backdrop hide-footer
      @show="showModal" @hidden="resetModal" @hide="resetModal">
      <form ref="form" @submit.stop.prevent="save">
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group id="input-group-2" :label="$t('addPart.interchange')" label-for="input-2">
              <b-form-select :disabled="interchangeList.length == 0" id="input-2" type="text" class="form-control"
                :placeholder="$t('addPart.interchange')" v-model="data.interchange" @change="onChangeInterchange($event)"
                :class="(!data.interchange && isInterchangeRequired) ? 'error-message' : ''">
                <!-- <option :value="null" :key="0">{{data.select_interchange_text}}</option> -->
                <option :value="null" v-if="interchangeList.length == 0">{{ $t("addPart.select_no_interchange_found")
                  }}</option>
                <option :value="null" v-if="interchangeList.length > 0">{{ $t("addPart.select_interchange") }}
                </option>
                <template v-for="(interchange, index) in interchangeList">
                  <template>
                    <option :v-if="interchange.IntchNbr"
                      v-bind:class="[interchange.IntchNbr ? 'font-weight-bold mb-3' : '']" :key="index + 50"
                      :value="interchange.InterchangeNumber" :disabled="!interchange.IntchNbr">{{
                        interchange.Application }}&nbsp;&nbsp;&nbsp;&nbsp;{{ interchange.InterchangeNumber ? '(' +
                        interchange.InterchangeNumber + ')' : '' }}
                    </option>
                  </template>
                </template>
              </b-form-select>
            </b-form-group>
            <span class="errMsg" v-if="isInterchangeRequired && !data.interchange">{{
              $t("validations.interchangeIsRequired")
            }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <div><span id="msg"></span></div>
            <div class="upload-images-wrapper row">
              <div class="upload-images col-md-12">
                <span class="mb-2 d-block"><b>{{ $t('addPart.part_image') }}</b> </span>
                <b-form-group id="input-group-01" label-for="input-01">
                  <image-uploader ref="img1" id="fileInput" :quality="0.9" outputFormat="verbose" :preview=false
                    :className="['hidden']" @input="onFileChange($event, 'img1')">
                    <label for="fileInput" slot="upload-label" class="node-image-uploader">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12 20H5C4.46957 20 3.96086 19.7893 3.58579 19.4142C3.21071 19.0391 3 18.5304 3 18V9C3 8.46957 3.21071 7.96086 3.58579 7.58579C3.96086 7.21071 4.46957 7 5 7H6C6.53043 7 7.03914 6.78929 7.41421 6.41421C7.78929 6.03914 8 5.53043 8 5C8 4.73478 8.10536 4.48043 8.29289 4.29289C8.48043 4.10536 8.73478 4 9 4H15C15.2652 4 15.5196 4.10536 15.7071 4.29289C15.8946 4.48043 16 4.73478 16 5C16 5.53043 16.2107 6.03914 16.5858 6.41421C16.9609 6.78929 17.4696 7 18 7H19C19.5304 7 20.0391 7.21071 20.4142 7.58579C20.7893 7.96086 21 8.46957 21 9V12.5M16 19H22M19 16V22"
                          stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                        <path data-v-74177b85=""
                          d="M9 13C9 13.7956 9.31607 14.5587 9.87868 15.1213C10.4413 15.6839 11.2044 16 12 16C12.7956 16 13.5587 15.6839 14.1213 15.1213C14.6839 14.5587 15 13.7956 15 13C15 12.2044 14.6839 11.4413 14.1213 10.8787C13.5587 10.3161 12.7956 10 12 10C11.2044 10 10.4413 10.3161 9.87868 10.8787C9.31607 11.4413 9 12.2044 9 13Z"
                          stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg>
                      <span class="upload-caption">{{ $t('AddCar.click_to_upload') }}</span>
                    </label>
                  </image-uploader>
                </b-form-group>
                <span class="no-wrap label label-danger" v-html="$t('addPart.default_part_image')">
                  <!-- {{
                    !(this.$route.params.uid && this.image['img1'])
                      ? $t("validations.required")
                      : ""
                  }} -->
                </span>
                <div id="img1" class="select-images "></div>
                <a id="img1-delIcon" v-if="this.image.length > 0" @click="deleteImage('img1')" class="delicon"
                  data-tooltip="Delete">
                  <!-- <img src="../assets/delete.svg" height="30"/> -->
                </a>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group id="input-group-7" :label="$t('addPart.recommendedSales')" label-for="input-7">
              <b-input-group>
                <b-input-group-prepend>
                  <span class="input-group-text">{{ '' | CurrencySymbol(country) }}</span>
                </b-input-group-prepend>
                <b-form-input id="input-7" type="number" :placeholder="$t('addPart.recommendedSales')"
                  v-model="data.recommended_sale_price" @input="" @keypress="validCost($event)">
                </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group id="input-group-8" :label="$t('addPart.partGrade')" label-for="input-8">
              <b-form-select id="input-8" type="text" class="form-control" :options="gradeOptions"
                :placeholder="$t('addPart.partGrade')" v-model="data.part_grade"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group id="input-group-10" :label="$t('addPart.warranty')" label-for="input-10">
              <b-form-select id="input-10" type="number" class="form-control" :options="warrantyOptions"
                v-model="data.warranty_days" :placeholder="$t('addPart.warranty')"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-lg-12 col-md-12 col-sm-12">
            <b-form-group id="input-group-10" :label="$t('addPart.description$')" label-for="input-10">
              <b-form-textarea id="textarea" :placeholder="$t('addPart.write_here')" rows="3" v-model="data.description"
                @input="$v.data.description.$touch()" @blur="$v.data.description.$touch()" :class="this.$v.data.description.$dirty &&
                  $v.data.description.$invalid
                  ? 'error-message'
                  : ''
                  " max-rows="6"></b-form-textarea>
            </b-form-group>
            <span class="errMsg"> {{ descriptionErrors[0] }}</span>
          </b-col>
        </b-row>
      </form>
      <div class="d-block text-center">
        <b-button class="mt-4 btn custom-btn custom-danger" @click="resetModal">
          {{ $t("confirmationDialog.cancel") }}
        </b-button>
        <b-button class="ml-2 mt-4 btn custom-btn pattern-btn" @click="handleOk">
          <span>{{ $t("confirmationDialog.save") }}</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { between, maxLength, required, requiredIf } from "vuelidate/lib/validators";
import { showFailure, showSuccess } from "@/eventbus/action";
import $ from "jquery";
import EventBus from "@/eventbus";

export default {
  props: {
    partId: {
      type: String,
      required
    }, partNumber: {
      type: String,
      required
    }, partName: {
      type: String,
      required
    }, type: {
      type: String,
      default: 'PartType'
    }, carProfileId: {
      type: String
    }, dialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      image: {},
      inputs: [
        {
          file: "",
        },
      ],
      files_url: "",
      selected: [],
      data: {
        partId: null,
        interchange: null,
        media_ids: null,
        part_name: null,
        part_grade: null,
        quantity: 0,
        warranty_days: null,
        part_location: null,
        yard_location: null,
        description: null,
        recommended_sale_price: "",
        select_interchange_text: "",
      },
      interchangeOptions: [
        { text: this.$t("commonLayout.select"), value: null },
      ],
      gradeOptions: [
        { text: this.$t("commonLayout.select"), value: null },
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "C", value: "C" },
      ],
      warrantyOptions: [
        { text: this.$t("commonLayout.select"), value: null },
        { text: "AS IS", value: "AS IS" },
        { text: "5 "+this.$t("commonLayout.days"), value: "5 "+this.$t("commonLayout.days") },
        { text: "15 "+this.$t("commonLayout.days"), value: "15 "+this.$t("commonLayout.days") },
        { text: "30 "+this.$t("commonLayout.days"), value: "30 "+this.$t("commonLayout.days") },
        { text: "60 "+this.$t("commonLayout.days"), value: "60 "+this.$t("commonLayout.days") },
        { text: "90 "+this.$t("commonLayout.days"), value: "90 "+this.$t("commonLayout.days") },
      ],
      isInterchangeRequired: false,
      country: 'us'
    }
  },
  async mounted() {
    this.country = await this.user?.user_detail?.country || 'us';
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      part: "partlist/getPart",
      // imageResponse: "partlist/getImageResponse",
      mediaImageResponse: "partlist/getMediaImageResponse",
      interchangeList: "partlist/getInterchange",
    }),
    partNameErrors() {
      const errors = [];
      if (!this.$v.data.part_name.$dirty) return errors;
      !this.$v.data.part_name.required &&
        errors.push(this.$t("validations.partNameIsRequired"));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.data.description.$dirty) return errors;
      !this.$v.data.description.maxLength &&
        errors.push(this.$t("validations.desMaxLength"));
      return errors;
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
    partId(val) {
      if (val) {
        this.getPartById();
      }
    },
    "$i18n.locale": function(nVal, oVal) {
      if(nVal != oVal) {
        this.interchangeOptions = [
          { text: this.$t("commonLayout.select"), value: null },
        ];
        this.gradeOptions = [
          { text: this.$t("commonLayout.select"), value: null },
          { text: "A", value: "A" },
          { text: "B", value: "B" },
          { text: "C", value: "C" },
        ];
        this.warrantyOptions = [
          { text: this.$t("commonLayout.select"), value: null },
          { text: "AS IS", value: "AS IS" },
          { text: "5 "+this.$t("commonLayout.days"), value: "5 "+this.$t("commonLayout.days") },
          { text: "15 "+this.$t("commonLayout.days"), value: "15 "+this.$t("commonLayout.days") },
          { text: "30 "+this.$t("commonLayout.days"), value: "30 "+this.$t("commonLayout.days") },
          { text: "60 "+this.$t("commonLayout.days"), value: "60 "+this.$t("commonLayout.days") },
          { text: "90 "+this.$t("commonLayout.days"), value: "90 "+this.$t("commonLayout.days") },
        ];
      }
    }
  },
  validations: {
    data: {
      part_name: { required },
      description: { maxLength: maxLength(150) },
    },
    imageResponseArray: [],
  },
  updated() {
    if (this.interchangeList.length < 1) {
      this.data.select_interchange_text = this.$t("partDialog.select_no_interchange_found");
      this.data.interchange = null;
    } else {
      this.data.select_interchange_text = this.$t("partDialog.select_interchange");
    }
  },
  methods: {
    ...mapActions({
      // uploadImage: "partlist/uploadImage",
      uploadMediaImage: "partlist/uploadMediaImage",
      addAuctionParts: "partlist/addAuctionParts",
      partsById: "partlist/getPartsById",
      getInterchange: "partlist/getInterchange",
      updateVehicleParts: "partlist/updateVehicleParts",
      addDirectParts: "partlist/addDirectParts",
      getPart: "partlist/getPart",
    }),

    async getPartById() {
      try {
        this.$store.commit("setLoader", true);
        let carProfileId = '';
        if (this.carProfileId) {
          carProfileId = this.carProfileId;
        } else {
          carProfileId = this.$route.params.id ? this.$route.params.id : this.$route.params.uid;
        }

        await this.getPart({
          id: this.partId,
          type: this.type,
          carProfileId: carProfileId,
          partName: this.partName,
        });

        await this.getInterchange({
          BeginYear: this.part.car_part.year,
          MfrCd: this.part.car_part.make_code,
          ModelNm: this.part.car_part.model,
          PartType: this.partNumber
        });

        if (this.part.car_part) {
          if (this.part.car_part.medias.length > 0 && this.part.car_part.medias[0].url100) {
            $(".select-image").empty();
            $(".select-image").append("<img class='img1' src='' alt=''>");
            $(".img1").attr("src", this.part.car_part.medias[0].url100);
          }
          this.data.media_ids = [this.part.car_part.medias].map(
            (val) => val.media_id
          );

          this.data.partId = this.partId;
          this.data.interchange = this.part.car_part.interchange_code;
          this.data.part_name = this.part.car_part.part_name;
          this.data.part_grade = this.part.car_part.part_grade;
          this.data.quantity = this.part.car_part.quantity;
          this.data.warranty_days = this.part.car_part.warranty_days;
          this.data.part_location = this.part.car_part.part_location;
          this.data.yard_location = this.part.car_part.yard_location;
          this.data.description = this.part.car_part.description;
          this.data.recommended_sale_price = this.part.car_part.recommended_sale_price;
        }
      } catch ({ message }) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    validateForm() {
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        return true;
      } else {
        return false;
      }
    },

    convertUSCurrency() {
      let currency = 0;
      if (this.data.recommended_sale_price) {
        currency = Number(
          this.data.recommended_sale_price.toString().replace(/[^0-9/.]/g, "")
        )
          .toString()
          .split(".");
      }

      if (!currency[1]) {
        this.data.recommended_sale_price = currency[0]
          .replace(/\D/g, "")
          .split("")
          .reverse()
          .reduce(function (acc, num, i) {
            return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
          }, "");
      } else {
        this.data.recommended_sale_price =
          currency[0]
            .replace(/\D/g, "")
            .split("")
            .reverse()
            .reduce(function (acc, num, i) {
              return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") +
          "." +
          currency[1];
      }
    },

    validCost(e) {
      const validate = this.data.recommended_sale_price.split(".");
      if (validate[1] && validate[1].length >= 2) {
        e.preventDefault();
      }
      const reg = /[^0-9]/;
      if (reg.test(e.key)) {
        e.preventDefault();
      }
    },

    showModal() {
      this.$refs["modal"].show();
    },
    hideModal() {
      this.resetData();
      EventBus.$emit("resetDialog");
      this.$refs["modal"].hide();
    },

    resetModal() {
      this.hideModal();
    },

    async saveImage(file, tag) {
      try {
        this.$store.commit("setLoader", true);
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        // await this.uploadImage(formData);
        await this.uploadMediaImage(formData);
        showSuccess("Image Saved Successfully");
        // return this.imageResponse;
        return this.mediaImageResponse;
        // this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({ message }) {
        this.$refs[tag].$el.children[1].value = "";
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async onFileChange(fileData, type) {
      $(`#${type}`).empty();
      $(`#${type}`).append(`<img class=${type} src='' alt=''>`);
      var selectedImage = await this.saveImage(fileData.dataUrl, type);
      this.image[type] = selectedImage.data;
      $(`.${type}`).attr("src", selectedImage.data.url100);
      $(`#${type}-delIcon`).show();
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.save()
    },

    onChangeInterchange(newInterchange) {
      if (this.interchangeList && this.interchangeList.length != 0) {
        this.isInterchangeRequired = true;
        // this.data.interchange = newInterchange;
      } else {
        this.isInterchangeRequired = false;
      }
    },

    async save() {
      this.data.part_name = this.part.name
      this.data.part_number = this.part.id
      if (!this.validateForm()) {
        console.log('invalid form...');
      }
      this.imageResponseArray = [];
      Object.keys(this.image).forEach((val) => {
        this.imageResponseArray.push(this.image[val]);
      });
      this.$v.data.$touch();
      this.onChangeInterchange();
      if (!this.$v.data.$invalid && ((this.isInterchangeRequired == true && typeof this.data.interchange === 'string') || this.isInterchangeRequired == false)) {
        try {
          this.$store.commit("setLoader", true);
          let payload = Object.assign({}, this.data);
          payload.country = this.country;
          payload.id = this.data.partId;
          payload.car_profile_id = this.$route.params.id ? this.$route.params.id : this.carProfileId;
          payload.is_on_facebook = this.selected.some(
            (val) => val === "is_on_facebook"
          );
          payload.is_on_ebay = this.selected.some(
            (val) => val === "is_on_ebay"
          );
          payload.is_on_instagram = this.selected.some(
            (val) => val === "is_on_instagram"
          );


          payload.interchange_code = this.data.interchange;
          payload.media_ids = this.imageResponseArray.map((val) => val.id);

          if (this.imageResponseArray.length <= 0) {
            if (this.part.car_part && this.part.car_part.medias && this.part.car_part.medias.length > 0) {
              this.data.media_ids = [this.part.car_part.medias[0].id];
              payload.media_ids = [this.part.car_part.medias[0].id];
            }
          }

          if (this.$route.params.id || this.carProfileId) {
            await this.addAuctionParts({
              ...payload,
            }).then(() => EventBus.$emit("getParts"));

            if (this.part.car_part) {
              showSuccess("Part Updated Successfully");
            } else {
              showSuccess("Part Added Successfully");
            }
          }

          this.resetData();
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing')
          })

          this.hideModal();

        } catch ({ message }) {
          console.log('Error : ', message);
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showFailure(errorMessage);
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },

    resetData() {
      this.data = {
        interchange: null,
        media_ids: null,
        part_name: null,
        part_grade: null,
        quantity: null,
        warranty_days: null,
        part_location: null,
        yard_location: null,
        description: null,
        recommended_sale_price: "",
        select_interchange_text: '',
      };
      this.image = {};
      this.inputs = [
        {
          file: "",
        },
      ];
      this.files_url = "";
      this.selected = [];
      $(".select-image").empty();
    }

  }
}
</script>
